<template>
  <div>
    <quotation-list-add-new
      :is-add-new-quotation-sidebar-active.sync="isAddNewQuotationSidebarActive"
      :status-options="statusOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>مدخلات</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="بحث..."
              />
              <b-button
                v-if="$can('create', 'quotation')"
                variant="primary"
                :to="{ name: 'quotations-add' }"
              >
                <span class="text-nowrap">إضافة {{ $t('quotation') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Quotation Table -->
      <b-table
        ref="refQuotationListTable"
        class="position-relative"
        :items="fetchQuotations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="لم يتم العثور على سجلات مطابقة"
        :sort-desc.sync="isSortDirDesc"
      >
      <!-- Column: reference -->
        <template #cell(reference)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
      <!-- Column: city -->
        <template #cell(city)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
      <!-- Column: estate_count -->
      <template #cell(estate_count)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>
      <!-- Column: total_cost -->
        <template #cell(total_cost)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
      <!-- Column: Status with dropdown -->
      <template #cell(status)="data">
        <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
          <template #button-content>
            <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <b-dropdown-item
            v-for="status in statusOptions"
            :key="status.value"
            @click="updateStatus(data.item.id, status.value)"
          >
            {{ status.label }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'quotations-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('data_entry', 'evaluationRequest')"
              @click="download(data.item.id, data.item.reference)"
            >
              <feather-icon icon="DownloadIcon" /> 
              <span class="align-middle ml-50">تصدير عرض السعر</span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmDelete(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">عرض من {{ dataMeta.from }} إلى {{ dataMeta.to }} من {{ dataMeta.of }} مدخلات</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalQuotation"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
    id="modal-notes-refuse"
    ref="modal-notes-refuse"
    title="سبب الرفض"
    ok-title="تأكيد"
    cancel-title="إلغاء"
    cancel-variant="outline-secondary"
    @ok="handleNotes"
    @hidden="resetModalNotes"
  >
    <div>
      <label for="notes-input">يرجى تقديم سبب الرفض (اختياري):</label>
      <input id="notes-input" v-model="notesInput" type="text" class="form-control" />
    </div>
  </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import QuotationListFilters from './QuotationListFilters.vue';
import useQuotationList from './useQuotationList';
import quotationStore from '../quotationStore';
import QuotationListAddNew from './QuotationListAddNew.vue';

export default {
  components: {
    QuotationListFilters,
    QuotationListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    vSelect,
  },
  data() {
    return {
      notesInput: '',
    };
  },
  methods: {
    download(quotation_id, reference) {
      this.$toast.info('جاري التنزيل...', {
        autoHide: false,
        appendToast: ToastificationContent,
      });
      store
        .dispatch('quotation/downloadQuotation', {
          id: quotation_id,
        })
        .then((response) => {
          setTimeout(() => {
            this.downloadFile(response.data, reference);
          }, 3000);
          this.$toast.success('تم التنزيل بنجاح');
        })
        .catch((error) => {
          console.error('Download error:', error);
          this.$toast.error('حدث خطأ اثناء التنزيل');
        });
    },
    downloadFile(blob, filename) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${filename}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    deleteQuotation(quotation) {
      store.dispatch('quotation/deleteQuotation', { id: quotation.id })
        .then((response) => {
          this.$emit('refetch-data');
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            quotationData.value = undefined;
          }
        });
    },
    confirmDelete(quotation) {
      this.$bvModal
        .msgBoxConfirm(`يرجى تأكيد أنك تريد حذف العنصر  ${quotation.id}.`, {
          title: 'يرجى التأكيد',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'نعم',
          cancelTitle: 'لا',
          cancelVariant: 'outline-warning',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteQuotation(quotation);
          }
        });
    },
    updateStatus(quotationId, newStatus) {
      if (newStatus === 'مرفوض') {
        this.$refs['modal-notes-refuse'].show();
        this.pendingUpdate = { quotationId, newStatus };
      } else {
        this.sendUpdateStatusRequest(quotationId, newStatus);
      }
    },
    handleNotes(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      const { quotationId, newStatus } = this.pendingUpdate;
      const notes = this.notesInput;
      // Trigger submit handler
      this.sendUpdateStatusRequest(quotationId, newStatus, notes);
      this.$nextTick(() => {
        this.$refs['modal-notes-refuse'].hide();
      });
    },
    resetModalNotes() {
      this.notesInput = '';
    },
    sendUpdateStatusRequest(quotationId, newStatus, notes = null) {
      const payload = { id: quotationId, newStatus };
      if (notes) {
        payload.notes = notes;
      }

    store
      .dispatch('quotation/updateStatus',  payload )
      .then(() => {
        this.refetchData();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'تم تحديث الحالة بنجاح',
            icon: 'CheckIcon',
            variant: 'success',
          },
        });

        // Show the modal if the status is "مقبول"
        if (newStatus === 'مقبول') {
          this.$bvModal
            .msgBoxConfirm('هل ترغب في انشاء عقد الآن ؟', {
              title: 'تأكيد',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'نعم',
              cancelTitle: 'لاحقا',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                // Redirect to create contract
                this.$router.push({ name: 'contracts-add', params: { quotationId:quotationId } });
              }
            });
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'حدث خطأ أثناء تحديث الحالة',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  },
  },
  setup() {
    const QUOTATION_STORE_MODULE_NAME = 'quotation';

    // Register quotation
    if (!store.hasModule(QUOTATION_STORE_MODULE_NAME)) store.registerModule(QUOTATION_STORE_MODULE_NAME, quotationStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUOTATION_STORE_MODULE_NAME)) store.unregisterModule(QUOTATION_STORE_MODULE_NAME);
    });

    const isAddNewQuotationSidebarActive = ref(false);
    const statusOptions = [
      {
        label: 'في الانتظار',
        value: 'في الانتظار',
      },
      {
        label: 'مقبول',
        value: 'مقبول',
      },
      {
        label: 'مرفوض',
        value: 'مرفوض',
      },
    ];

    const {
      fetchQuotations,
      tableColumns,
      perPage,
      currentPage,
      totalQuotation,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refQuotationListTable,
      refetchData,
      statusFilter,
    } = useQuotationList();

    return {
      isAddNewQuotationSidebarActive,
      fetchQuotations,
      tableColumns,
      perPage,
      currentPage,
      totalQuotation,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refQuotationListTable,
      refetchData,
      avatarText,
      statusOptions,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
